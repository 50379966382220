import ThemeSwitch from "./ThemeSwitch";
import { Disclosure, DisclosureButton, DisclosurePanel } from "@headlessui/react";
import React from 'react';
import { Link } from 'react-router-dom';

import { LanguageIcon } from "@heroicons/react/24/outline";

import logo from "../assets/img/logo.webp";

import { useLocale } from "../context/localeContext";
import { useTranslation } from "react-i18next";

export default function Navbar() {
  const { setLocale } = useLocale();

  const { i18n } = useTranslation();
  const { t } = useTranslation(["base"]);

  const handleLanguageChange = (e) => {
    setLocale()
    i18n.changeLanguage(i18n.language === "en" ? "de" : "en");
  }

  return (
    <div className="w-full">
      <nav className="container relative flex flex-wrap items-center justify-between p-8 mx-auto lg:justify-between xl:px-0">
        {/* Logo  */}
        <Disclosure>
          {({ open }) => (
            <>
              <div className="flex flex-wrap items-center justify-between w-full lg:w-auto">
                <Link to="/">
                  <span className="flex items-center space-x-2 text-2xl font-medium text-indigo-500">

                    <span>
                      <img
                        src={logo}
                        alt="N"
                        width="36"
                        height="36"
                        className="w-11"
                      />
                    </span>
                    <span>enki</span>
                  </span>
                </Link>

                <DisclosureButton
                  aria-label="Toggle Menu"
                  className="px-2 py-1 ml-auto text-gray-500 rounded-md lg:hidden hover:text-indigo-500 focus:text-indigo-500">
                  <svg
                    className="w-6 h-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24">
                    {open && (
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                      />
                    )}
                    {!open && (
                      <path
                        fillRule="evenodd"
                        d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                      />
                    )}
                  </svg>
                </DisclosureButton>

                <DisclosurePanel className="flex flex-col w-full my-5 lg:hidden">
                  <Link to="/#services" className="w-full px-8 py-4 text-center dark:text-white">{t("navbar.link.services")}</Link>
                  <Link to="/#projects" className="w-full px-8 py-4 text-center dark:text-white">{t("navbar.link.projects")}</Link>
                  <Link to="/#company" className="w-full px-8 py-4 text-center dark:text-white">{t("navbar.link.company")}</Link>
                  <Link to="/customml" className="px-8 py-4 text-center dark:text-white ">{t("navbar.link.customml")}</Link>
                  <Link to="/consulting" className="px-8 py-4 text-center dark:text-white">{t("navbar.link.consulting")}</Link>
                  <Link to="/education" className="px-8 py-4 text-center dark:text-white ">{t("navbar.link.education")}</Link>
                </DisclosurePanel>
              </div>
            </>
          )}
        </Disclosure>

        {/* menu  */}
        <div className="hidden text-center lg:flex lg:items-center">
          <ul className="items-center justify-end flex-1 pt-6 list-none lg:pt-0 lg:flex">
            <Link to="/#services" className="px-4 py-4 text-center dark:text-white">{t("navbar.link.services")}</Link>
            <Link to="/#projects" className="px-4 py-4 text-center dark:text-white">{t("navbar.link.projects")}</Link>
            <Link to="/#company" className="px-4 py-4 text-center dark:text-white">{t("navbar.link.company")}</Link>
            <Link to="/customml" className="px-4 py-4 text-center dark:text-white ">{t("navbar.link.customml")}</Link>
            <Link to="/consulting" className="px-4 py-4 text-center dark:text-white">{t("navbar.link.consulting")}</Link>
            <Link to="/education" className="px-4 py-4 text-center dark:text-white ">{t("navbar.link.education")}</Link>
          </ul>
        </div>

        <div className="hidden mr-3 space-x-4 lg:flex nav__item">
          <ThemeSwitch />
          <button
            onClick={handleLanguageChange}
            className="outline-none dark:text-gray-200 hover:text-indigo-500 "
          >
            <LanguageIcon className="w-6 h-6" />
          </button>
        </div>
      </nav>
    </div>
  );
}
